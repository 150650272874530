import React, { useEffect } from 'react';

import { isBrowser } from '../generic/common';

const initGA = () => {
  if (isBrowser()) {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'UA-125468800-2');
  }
};

export const GAnalytics = () => {
  useEffect(() => {
    if (isBrowser()) {
      initGA();
    }
  }, []);

  return (
    <></>
  );
};